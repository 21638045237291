import * as React from "react";
import { createTheme } from "@mui/material/styles";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import Dashboard from "./Dashboard";
import logo from "./../../images/logo.png";
import { Menu, MenuItem } from "@mui/material";
import TopNavigation from "./TopNavigation";
import { AuthContext } from "../../ContextProviders/AuthProvider";
import { Navigate } from "react-router-dom";
import axiosInstance from "../../axiosConfig";
const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function Main(props) {
  return (
    <AppProvider
      theme={demoTheme}
      branding={{
        logo: <img src={logo} alt="Nitro logo" />,
        title: "",
      }}
    >
      <TopNavigation />
      <Dashboard />
    </AppProvider>
  );
}

export default Main;
