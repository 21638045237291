import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MuiFileInput } from "mui-file-input";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogBox from "./DialogBox";
import dayjs from "dayjs";
import axiosInstance from "../../axiosConfig";

import {
  FormContainer,
  TextFieldElement,
  TextareaAutosizeElement,
  SelectElement,
} from "react-hook-form-mui";
import { deviceDetect } from "react-device-detect";
import axios from "axios";

export default function Mainform() {
  const [issueType, setIssueType] = useState("IT");
  const [subIssueType, setSubIssueType] = useState("hardware");
  const [subIssueName, setSubIssueName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [description, setDescription] = useState("");
  const [issueDate, setIssueDate] = useState(dayjs());
  const [Priority, setPriority] = useState(null);
  const [staffName, setStaffName] = useState("");
  const [machineMake, setMachineMake] = useState("");
  const [machineIssue, setMachineIssue] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ExternalRefNumber, setExternalRefNumber] = useState(null);
  const [ip, setIp] = useState(null);
  const [userAgent, setUserAgent] = useState(null);

  useEffect(
    () => async () => {
      const userAgentData = deviceDetect(window.navigator.userAgent);
      const userAgent = `Browser:${userAgentData.browserName} OsName:${userAgentData.osName} IsBrowser:${userAgentData.isBrowser}`;
      setUserAgent(userAgent);
      const result = axios
        .get("https://api.ipify.org/?format=json")
        .then((res) => {
          setIp(res.data.ip);
        })
        .catch((err) => {
          console.log(`Could not get IP Address`);
        });
    },
    []
  );

  const submit = async () => {
    let getSite = localStorage.getItem("site");
    let localSite = getSite?.replace(/['"]+/g, "");

    if (
      (localSite === "" || localSite === undefined) &&
      window.location.pathname !== "/admin"
    ) {
      setErrorMessage("Could not find the site parameter please try again!");
      setIsOpenAlert(true);
    } else {
      if (window.location.pathname === "/admin") localSite = "Head Office";
      const userData = {
        name: staffName,
        date: dayjs(issueDate).format("DD-MM-YYYY"),
        issueType: issueType,
        subissueTye: subIssueType,
        subIssueName: subIssueName,
        serialNumber: serialNumber,
        machineMake: machineMake,
        machineIssue: machineIssue,
        description: description,
        islogged: isLogged,
        image: image !== null ? await convertToBase64(image) : null,
        priority: Priority,
        site: localSite,
        ExternalRefNumber: ExternalRefNumber,
        ip: ip,
        userAgent: userAgent,
      };

      setLoading(true);
      SendData(userData);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function SendData(userData) {
    console.log(userData);

    try {
      const result = await axiosInstance({
        method: "post",
        url: "ticket",
        responseType: "json",
        data: userData,
      });

      if (result.data.success === true) {
        setErrorMessage("Issue has been submitted successfully  ");
        setIsOpenAlert(true);
        setStaffName("");
        setIssueDate(null);
        setSubIssueName("");
        setSerialNumber("");
        setDescription("");
        setIsLogged(false);
        setPriority(null);
        setImage(null);
        window.location.reload();
      } else {
        setErrorMessage("Bad request Please try again ");
        setIsOpenAlert(true);
      }
    } catch (error) {
      if (error.status === 400) {
        const value = error.response.data.details[0];
        setErrorMessage(value[Object.keys(value)[0]]);
        setIsOpenAlert(true);
      } else {
        setErrorMessage("Network error please try again");
        setIsOpenAlert(true);
      }
    }
    setLoading(false);
  }

  return (
    <>
      <DialogBox
        openState={isOpenAlert}
        title="Message"
        setOpenState={setIsOpenAlert}
        data={errorMessage}
      />
      <FormContainer onSuccess={submit}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <LocalizationProvider
            style={{ width: "100%" }}
            dateAdapter={AdapterDayjs}
          >
            <DatePicker
              required
              name="IssueDate"
              value={issueDate}
              onChange={(e) => setIssueDate(e)}
            />
          </LocalizationProvider>

          <TextFieldElement
            name="name"
            label="Name"
            required
            value={staffName}
            onChange={(e) => setStaffName(e.target.value)}
          />
        </div>
        <FormControl fullWidth margin="dense">
          <SelectElement
            label="Issue Type"
            name="IssueType"
            onChange={(e) => setIssueType(e)}
            options={[
              {
                id: "IT",
                label: "IT Support",
              },
              {
                id: "REPAIR",
                label: "Repair",
              },
            ]}
            required
          />
        </FormControl>
        {issueType === "IT" && (
          <FormControl fullWidth margin="dense">
            <SelectElement
              label="Please Select between Hardware or Software"
              name="SubIssueType"
              required
              options={[
                {
                  id: "hardware",
                  label: "Hardware",
                },
                {
                  id: "software",
                  label: "Software",
                },
              ]}
              onChange={(e) => {
                setSubIssueType(e);
              }}
            />
          </FormControl>
        )}

        <TextFieldElement
          name="subIssueName"
          fullWidth
          required
          label={
            subIssueType === "software"
              ? "Software Name"
              : "Machine/Hardware Type"
          }
          value={subIssueName}
          onChange={(e) => setSubIssueName(e.target.value)}
          margin="dense"
        />
        <TextFieldElement
          name="assetName"
          required
          fullWidth
          label="Asset Tag/Serial Number"
          margin="dense"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
        />
        {issueType === "IT" && (
          <FormControl fullWidth margin="dense">
            <SelectElement
              required
              name="priority"
              label="Priority"
              options={[
                {
                  id: "critical",
                  label: "Critical(sitedown)",
                },
                {
                  id: "high",
                  label: "High",
                },
                {
                  id: "medium",
                  label: "medium",
                },
                {
                  id: "low",
                  label: "Low",
                },
              ]}
              onChange={(e) => setPriority(e)}
            />
          </FormControl>
        )}

        {issueType === "REPAIR" && (
          <FormControl fullWidth margin="dense">
            <SelectElement
              required
              name="machineMake"
              label="Machine Make *"
              options={[
                {
                  id: "blue ice",
                  label: "Blue Ice",
                },
                {
                  id: "vertex",
                  label: "Vertex",
                },
                {
                  id: "pelican rouge",
                  label: "Pelican Rouge",
                },
                {
                  id: "vending Sense",
                  label: "Vending Sense",
                },
                {
                  id: "taylors",
                  label: "Taylors",
                },
                {
                  id: "A Cunningham & Company",
                  label: "A Cunningham & Company",
                },
                {
                  id: "Abbey Services",
                  label: "Abbey Services",
                },
                {
                  id: "Air Serve",
                  label: "Air Serve",
                },
                {
                  id: "Clyde Valley Lifts",
                  label: "Clyde Valley Lifts",
                },
                {
                  id: "TSG",
                  label: "TSG",
                },
                {
                  id: "TLM",
                  label: "TLM",
                },
                {
                  id: "Gartec",
                  label: "Gartec",
                },
                {
                  id: "Stannah",
                  label: "Stannah",
                },
                {
                  id: "Bell Fire",
                  label: "Bell Fire",
                },
                {
                  id: "Fairbanks",
                  label: "Fairbanks",
                },
                {
                  id: "Photome",
                  label: "Photome",
                },
                {
                  id: "Catering Appliance",
                  label: "Catering Appliance",
                },
                {
                  id: "RT Maintenance",
                  label: "RT Maintenance",
                },
                {
                  id: "Washing Machine",
                  label: "Washing Machine",
                },
              ]}
              onChange={(e) => setMachineMake(e)}
            />
          </FormControl>
        )}

        {issueType === "REPAIR" && (
          <FormControl fullWidth margin="dense">
            <SelectElement
              name="machineIssue"
              required
              label="What is the issue *"
              onChange={(e) => setMachineIssue(e)}
              options={[
                {
                  id: "fault",
                  label: "Fault",
                },
                {
                  id: "demaged",
                  label: "Demaged",
                },
              ]}
            />
          </FormControl>
        )}
        {issueType === "REPAIR" && (
          <FormControlLabel
            fullWidth
            control={<Checkbox />}
            label="Click Here If You Have Logged This Issue"
            margin="dense"
            value={isLogged}
            onChange={(e) => setIsLogged(e.target.checked)}
          />
        )}
        <TextareaAutosizeElement
          name="Description"
          required
          fullWidth
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          margin="dense"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextareaAutosizeElement
          name="ExternalRefNumber"
          fullWidth
          id="ExternalRefNumber"
          label="External reference number(optional)"
          margin="dense"
          value={ExternalRefNumber}
          onChange={(e) => setExternalRefNumber(e.target.value)}
        />
        {issueType === "REPAIR" && (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
            margin="dense"
          >
            <AttachFileIcon
              fontSize="small"
              style={{ position: "absolute", left: "3px", display: "flex" }}
            />
            <MuiFileInput
              placeholder="Upload Any Images (optional)"
              clearIconButtonProps={{
                title: "Remove",
                children: <CloseIcon fontSize="large" />,
              }}
              inputProps={{ accept: ".png, .jpeg" }}
              value={image}
              onChange={(file) => setImage(file)}
            />
          </div>
        )}
        <LoadingButton
          variant="outlined"
          style={{ marginTop: "15px" }}
          margin="dense"
          loading={loading === true ? true : false}
          type="submit"
        >
          Submit
        </LoadingButton>
      </FormContainer>
    </>
  );
}
