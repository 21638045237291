import { useState, React } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../../axiosConfig";
import Chip from "@mui/material/Chip";

import {
  FormContainer,
  TextFieldElement,
  RadioButtonGroup,
} from "react-hook-form-mui";
import {
  Alert,
  Autocomplete,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Fragment } from "react";

export default function AssignedTeams(props) {
  const { teams, ticket_id } = props;

  const [OpenChangeStatus, setOpenChangeStatus] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const [alerttext, setAlertText] = useState(null);
  const [ListOpen, setListOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [SelectedValues, setSelectedValues] = useState(teams);

  const updateTeams = async () => {
    let team_ids = SelectedValues.map((element) => {
      return element.id;
    });

    try {
      const result = await axiosInstance({
        method: "post",
        url: "ticket/assignedto",
        responseType: "json",
        data: {
          id: ticket_id,
          team_ids: team_ids,
        },
      });
      if (result.data.success === true) {
        setOpenChangeStatus(false);
        window.location.reload();
      } else {
        setAlertText(result.data.message);
        setshowalert(true);
      }
    } catch (error) {
      setAlertText(error.message);
      setshowalert(true);
    }
  };

  const handleListOpen = () => {
    setListOpen(true);
    (async () => {
      setLoading(true);
      const teams = await axiosInstance({
        method: "get",
        url: "user/teams",
        responseType: "json",
      });
      setLoading(false);
      setOptions(teams.data.data);
    })();
  };

  const handleListClose = () => {
    setListOpen(false);
    setOptions([]);
  };

  const handleClickOpen = () => {
    setOpenChangeStatus(true);
  };

  const handleClose = () => {
    setshowalert(false);
    setOpenChangeStatus(false);
  };
  return (
    <Fragment>
      <IconButton onClick={handleClickOpen} aria-label="note">
        <Chip color={"info"} label={"Assigned Team"} />
      </IconButton>

      <Dialog maxWidth={"lg"} open={OpenChangeStatus} onClose={handleClose}>
        <FormContainer onSuccess={(data) => updateTeams(data)}>
          <DialogTitle>Assign Teams</DialogTitle>

          {showalert && <Alert severity="error">{alerttext}</Alert>}

          <DialogContent>
            <DialogContentText>
              <Autocomplete
                fullWidth
                multiple
                sx={{ width: "350px", margin: "10px" }}
                open={ListOpen}
                onOpen={handleListOpen}
                onClose={handleListClose}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={options}
                defaultValue={teams}
                loading={loading}
                onChange={(e, v) => setSelectedValues(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Teams"
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      },
                    }}
                  />
                )}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>close</Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Fragment>
  );
}
