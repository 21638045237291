import { useState, Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import EditNoteIcon from "@mui/icons-material/EditNote";
import axiosInstance from "../../axiosConfig";
import Alert from "@mui/material/Alert";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { FormContainer, TextareaAutosizeElement } from "react-hook-form-mui";

export default function AddNotes(props) {
  const { id, icon } = props;
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alerttext, setAlertText] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const addNote = async () => {
    const data = { id: id, note: note };
    try {
      const result = await axiosInstance({
        method: "post",
        url: "note",
        responseType: "json",
        data: data,
      });

      if (result.data.success === true) {
        setOpen(false);
        window.location.reload();
      } else {
        setAlertText(result.data.message);
        setshowalert(true);
      }
    } catch (error) {
      setAlertText(error.message);
      setshowalert(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton onClick={handleClickOpen} aria-label="note">
        {icon === "add" ? (
          <AddCircleOutlineSharpIcon color="info" />
        ) : (
          <EditNoteIcon />
        )}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <FormContainer onSuccess={addNote}>
          <DialogTitle>Add Note</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add note to this issue, please type your note below box and
              click save.
            </DialogContentText>
            {showalert && <Alert severity="error">{alerttext}</Alert>}
            <br />
            <TextareaAutosizeElement
              fullWidth
              label="Add Note"
              multiline
              name="addnote"
              rows={4}
              margin="dense"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">save</Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Fragment>
  );
}
