import { useState, Fragment, React, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import axiosInstance from "../../axiosConfig";
import DescriptionIcon from "@mui/icons-material/Description";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function ViewLogs(props) {
  const { ticket_id } = props;

  const [OpenChangeStatus, setOpenChangeStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ticketLog, setTicketLog] = useState([]);

  const handleClickOpen = () => {
    setOpenChangeStatus(true);
  };

  const handleClose = () => {
    setOpenChangeStatus(false);
  };

  async function fetchData(id) {
    setLoading(true);
    try {
      const result = await axiosInstance({
        method: "get",
        url: "ticket/logs/" + id,
        responseType: "json",
      });

      if (result.data.data) {
        setTicketLog(result.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData(ticket_id);
  }, []);

  return (
    <Fragment>
      <IconButton onClick={handleClickOpen} aria-label="note">
        <Chip color={"info"} label={"View logs"} />
      </IconButton>

      <Dialog maxWidth={"lg"} open={OpenChangeStatus} onClose={handleClose}>
        <DialogTitle>Log History</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Added by</StyledTableCell>
                    <StyledTableCell align="left">added date</StyledTableCell>
                    <StyledTableCell align="left">action </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ticketLog?.map((row, key) => (
                    <TableRow key={key}>
                      <TableCell align="left" style={{ width: "120px" }}>
                        {row?.username}
                      </TableCell>
                      <TableCell align="left" style={{ width: "170px" }}>
                        {row?.created_at}
                      </TableCell>
                      <TableCell align="left">{row?.action}</TableCell>
                    </TableRow>
                  ))}
                  {loading && <CircularProgress />}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
