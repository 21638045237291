import * as React from "react";
import { createTheme } from "@mui/material/styles";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";

import logo from "./../../images/logo.png";
import Submitform from "./Submitform";
const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function Dashbaord(props) {
  return (
    <AppProvider
      theme={demoTheme}
      branding={{
        logo: <img src={logo} alt="Nitro logo" />,
        title: "",
      }}
    >
      <DashboardLayout hideNavigation>
        <Submitform />
      </DashboardLayout>
    </AppProvider>
  );
}

export default Dashbaord;
