import React, { useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axiosInstance from "../../axiosConfig";
import TicketDetail from "./TicketDetail";
import DialogBox from "../User/DialogBox";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Badge,
  Box,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TableFooter,
  TablePagination,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useTheme } from "@mui/material/styles";

import Mainform from "../User/MainForm";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import BulkStatusChange from "./BulkStatusChange";
import { AuthContext } from "../../ContextProviders/AuthProvider";
import { Navigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function TicketList() {
  const [tickets, setTickets] = useState([]);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const [filter, setFilter] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [bulkIDs, setBulkIDs] = React.useState([]);

  const { auth, setAuth } = useContext(AuthContext);

  const open = Boolean(anchorEl);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tickets.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    fetchData(filter);
    setAnchorEl(null);
  };
  const clearFilter = () => {
    fetchData();
    setFilter(null);
    setAnchorEl(null);
  };

  async function fetchData(filter = "0") {
    setLoading(true);
    const url = filter === "0" ? "ticket" : "ticket/" + filter;

    try {
      const result = await axiosInstance({
        method: "get",
        url: url,
        responseType: "json",
      });
      if (result.data.success === true) {
        setTickets(result.data.data);
      } else {
        setErrorMessage(result.data.message);
        setIsOpenAlert(true);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setIsOpenAlert(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        overflowX: "auto",
        padding: "10px",
      }}
    >
      <DialogBox
        openState={isOpenAlert}
        title="Message"
        setOpenState={setIsOpenAlert}
        data={errorMessage}
      />
      <Box
        style={{
          display: "flex",
          height: "30px",
          width: "100%",
          marginBottom: "10px",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
        }}
        component={Paper}
      >
        <div>
          <Badge
            badgeContent={filter !== null ? 1 : 0}
            color="primary"
            onClick={handleClick}
            style={{ marginRight: "50px" }}
          >
            <TuneIcon color="action" />
          </Badge>

          {bulkIDs.length > 1 && <BulkStatusChange ids={bulkIDs} />}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{ padding: "50px" }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ padding: "10px" }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label1">
                  Filters by Status
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label1"
                  value={filter === null ? "" : filter}
                  name="radio-buttons-group1"
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Open tickets"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Closed tickets"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Investigation started"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="wating on piarts"
                  />
                </RadioGroup>
              </FormControl>

              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                <Button variant="text" onClick={handleClose}>
                  Apply
                </Button>
                <Button variant="text" onClick={clearFilter}>
                  Clear
                </Button>
              </div>
            </div>
          </Menu>
        </div>
        <div>
          {/* create ticket button  */}
          <IconButton
            onClick={() => {
              setOpenForm(true);
            }}
          >
            <AddCircleIcon color="primary" />
          </IconButton>
        </div>
      </Box>

      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={openForm}
          onClose={() => {
            setOpenForm(false);
          }}
        >
          <div style={{ padding: "50px" }}>
            <Mainform />
          </div>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenForm(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="customized table" sx={{ overflow: "scroll" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      let list = document.getElementsByClassName("singleRow");
                      let ids = [];
                      for (let item of list) {
                        ids = [...ids, item.getAttribute("id")];
                        item.checked = true;
                      }
                      setBulkIDs(ids);
                    } else {
                      let list = document.getElementsByClassName("singleRow");
                      for (let item of list) {
                        setBulkIDs([]);
                        item.checked = false;
                        item.removeAttribute("checked");
                      }
                    }
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>Ticket Number</StyledTableCell>
              <StyledTableCell align="left">Site</StyledTableCell>
              <StyledTableCell align="left">Title of Issue</StyledTableCell>
              <StyledTableCell align="left">Current Status</StyledTableCell>
              <StyledTableCell align="left">Assigned Teams</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tickets.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tickets
            ).map((row) => (
              <TicketDetail
                ticket={row}
                key={row.id}
                setBulkIDs={setBulkIDs}
                bulkIDs={bulkIDs}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            {(tickets.length !== 0 || loading) && (
              <TableRow style={{ alignItems: "center" }}>
                <span></span>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={tickets.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  align="center"
                />
              </TableRow>
            )}
          </TableFooter>
        </Table>
        <center>
          {loading && <CircularProgress style={{ margin: "20px" }} />}
        </center>
        {tickets.length === 0 && !loading && (
          <h3 style={{ textAlign: "center" }}>No data to show</h3>
        )}
      </TableContainer>
    </div>
  );
}
