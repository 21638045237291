import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddNotes from "../User/AddNotes";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import ChangeStatus from "../User/ChangeStatus";
import Viewimage from "./ViewImage";
import DescriptionIcon from "@mui/icons-material/Description";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ListIcon from "@mui/icons-material/List";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import BugReportIcon from "@mui/icons-material/BugReport";
import BuildIcon from "@mui/icons-material/Build";
import HardwareIcon from "@mui/icons-material/Hardware";
import { Chip } from "@mui/material";
import ViewLogs from "./ViewLogs";
import AssignedTeams from "./AssignedTeams";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function TicketDetail(props) {
  const { ticket, setBulkIDs, bulkIDs } = props;

  const date = dayjs();

  const days = date.diff(
    dayjs(ticket?.inserted_at).format("YYYY-MM-DD HH:mm:ss"),
    "day"
  );
  const hours = date.diff(
    dayjs(ticket?.inserted_at).format("YYYY-MM-DD HH:mm:ss"),
    "hour"
  );

  const minutes = date.diff(
    dayjs(ticket?.inserted_at).format("YYYY-MM-DD HH:mm:ss"),
    "minute"
  );
  const seconds = date.diff(
    dayjs(ticket?.inserted_at).format("YYYY-MM-DD HH:mm:ss"),
    "second"
  );

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledTableRow
        key={ticket.id}
        hover={true}
        style={{ cursor: "pointer" }}
      >
        <StyledTableCell component="th" scope="row">
          <input
            type="checkbox"
            className="singleRow"
            id={ticket?.id}
            onChange={(e) => {
              if (e.target.checked === false) {
                let arr = bulkIDs;
                const index = arr.indexOf(e.target.id);
                const x = arr.splice(index, 1);
                setBulkIDs(arr);
              } else setBulkIDs([...bulkIDs, e.target.id]);
            }}
          />
        </StyledTableCell>
        <StyledTableCell
          component="th"
          scope="row"
          onClick={() => {
            setOpen(true);
          }}
        >
          {ticket?.id}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          onClick={() => {
            setOpen(true);
          }}
        >
          {ticket?.site}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          onClick={() => {
            setOpen(true);
          }}
        >
          {ticket.issue_type === "IT" ? "IT Service Request" : "Repair Request"}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          onClick={() => {
            setOpen(true);
          }}
        >
          <Chip
            label={ticket?.main_status === null ? "Open" : ticket?.main_status}
            size="small"
            variant="outlined"
            color="info"
          />
        </StyledTableCell>
        <StyledTableCell
          align="left"
          onClick={() => {
            setOpen(true);
          }}
        >
          {ticket?.teams.length === 0
            ? "--"
            : ticket.teams.map((team, index) => {
                return (
                  <Chip
                    sx={{ marginRight: "10px" }}
                    key={index}
                    color="default"
                    label={team.name}
                  />
                );
              })}
        </StyledTableCell>
      </StyledTableRow>

      <React.Fragment>
        <BootstrapDialog maxWidth={"xl"} onClose={handleClose} open={open}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialogtitle">
            Ticket Details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "20px",
                Padding: "10px",
              }}
            >
              <Box
                style={{
                  flex: 1,
                  height: "50px",
                  display: "flex",
                  margin: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                component={Paper}
              >
                <span> ID: {ticket?.id}</span>
              </Box>
              <Box
                style={{
                  flex: 4,
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                component={Paper}
              >
                <span>
                  {ticket.issue_type === "IT"
                    ? "IT Service Request"
                    : "Repair Request"}{" "}
                </span>
              </Box>
              <Box
                style={{
                  flex: 2,
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                component={Paper}
              >
                <b>
                  {ticket?.main_status === null ? "Open" : ticket?.main_status}{" "}
                </b>
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Box style={{ flex: 1, margin: "5px" }} component={Paper}>
                <List dense={true}>
                  <ListItem>
                    <ListItemIcon>
                      <LanguageIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Site" secondary={ticket?.site} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Submitted By"
                      secondary={ticket?.staff_name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <AccessTimeIcon color="action" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Submitted Date and time"
                      secondary={ticket?.inserted_at}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DescriptionIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Description"
                      secondary={ticket?.issue_description}
                    />
                  </ListItem>
                </List>
              </Box>
              <Box style={{ flex: 1, margin: "5px" }} component={Paper}>
                <List dense={true}>
                  {ticket?.issue_priority && (
                    <ListItem>
                      <ListItemIcon>
                        <WarningAmberIcon color="warning" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Priority"
                        secondary={ticket?.issue_priority}
                      />
                    </ListItem>
                  )}
                  {ticket?.issue_priority && (
                    <ListItem>
                      <ListItemIcon>
                        <HardwareIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Issue type"
                        secondary={ticket?.sub_issue_type}
                      />
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemIcon>
                      <ListIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Software/Hardware ${ticket?.issue_type === "IT" ? "name" : "type"}`}
                      secondary={ticket?.hardware_type}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FingerprintIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Serial Number"
                      secondary={ticket?.serial_number}
                    />
                  </ListItem>
                  {!ticket?.issue_priority && (
                    <ListItem>
                      <ListItemIcon>
                        <BuildIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Machine Make"
                        secondary={ticket?.machine_make}
                      />
                    </ListItem>
                  )}
                  {!ticket?.issue_priority && (
                    <ListItem>
                      <ListItemIcon>
                        <BugReportIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Machine Issue"
                        secondary={ticket?.machine_issue}
                      />
                    </ListItem>
                  )}
                </List>
              </Box>

              <Box style={{ flex: 0.7 }}>
                <Box style={{ flex: 5, margin: "10px" }} component={Paper}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <AccessTimeIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Current Ticket Time"
                        secondary={
                          days +
                          " days " +
                          (hours - 24 * days) +
                          " Hours " +
                          (minutes - 60 * hours) +
                          " Minutes  " +
                          (seconds - 60 * minutes) +
                          " Seconds"
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
                <Box style={{ flex: 2, margin: "10px" }} component={Paper}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <HistoryIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Last Updated at"
                        secondary={dayjs(ticket?.lastupdated_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Box style={{ flex: 2, margin: "10px" }} component={Paper}>
                <Box style={{ flex: 5 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Added by
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            added date
                          </StyledTableCell>
                          <StyledTableCell align="left">Note </StyledTableCell>
                          <StyledTableCell align="right">
                            <AddNotes icon="add" id={ticket?.id} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ticket?.allNotes.map((row, key) => (
                          <TableRow key={key}>
                            <TableCell align="left" style={{ width: "120px" }}>
                              {row?.added_by}
                            </TableCell>
                            <TableCell align="left" style={{ width: "170px" }}>
                              {row?.created_at}
                            </TableCell>
                            <TableCell align="left">{row?.note}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>

              <Box
                style={{
                  margin: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                }}
                component={Paper}
              >
                <div>
                  <Box
                    style={{
                      width: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "10px",
                      display: "flex",
                      height: "80px",
                    }}
                    component={Paper}
                  >
                    {/* <span>View Logs</span> */}
                    <ViewLogs ticket_id={ticket.id} />
                  </Box>
                  <Box
                    style={{
                      width: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "10px",
                      display: "flex",
                      height: "80px",
                    }}
                    component={Paper}
                  >
                    <ChangeStatus status={"Change Status"} id={ticket.id} />
                  </Box>
                </div>
                <div>
                  <Box
                    style={{
                      width: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      margin: "10px",
                      height: "80px",
                    }}
                    component={Paper}
                  >
                    <Viewimage status={"View Image"} image={ticket.image} />
                  </Box>

                  <Box
                    style={{
                      width: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      margin: "10px",
                      height: "80px",
                    }}
                    component={Paper}
                  >
                    <AssignedTeams
                      teams={ticket?.teams}
                      ticket_id={ticket?.id}
                    />
                  </Box>
                </div>
              </Box>
            </Box>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
