import { useState, Fragment, React, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../../axiosConfig";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

export default function ShowNote(props) {
  const { Ticket_id } = props;

  const [OpenCard, setOpenCard] = useState(false);
  const [Notes, setNotes] = useState([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  async function fetchData(id) {
    try {
      const result = await axiosInstance({
        method: "get",
        url: "note/" + id,
        responseType: "json",
      });

      setNotes(result.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData(Ticket_id);
  }, []);

  const handleClickOpen = () => {
    setOpenCard(true);
  };

  const handleClose = () => {
    setOpenCard(false);
  };
  return (
    <Fragment>
      <IconButton onClick={handleClickOpen} aria-label="note">
        <StickyNote2Icon />
      </IconButton>
      <Dialog open={OpenCard} onClose={handleClose}>
        <DialogTitle>Notes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Notes.length === 0 ? (
              <h4>There are no notes</h4>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Added by</StyledTableCell>
                      <StyledTableCell align="left">added date</StyledTableCell>
                      <StyledTableCell align="left">Note </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Notes?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left" style={{ width: "120px" }}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left" style={{ width: "170px" }}>
                          {dayjs(row.created_at).format("YYYY-MM-DD HH:mm:ss")}
                        </TableCell>
                        <TableCell align="left">{row.note}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
