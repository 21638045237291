import { createTheme, Typography } from "@mui/material";
import { AppProvider, DashboardLayout } from "@toolpad/core";
import React from "react";
import logo from "./../../images/logo.png";
import { Link } from "react-router-dom";

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function Verification() {
  return (
    <AppProvider
      theme={demoTheme}
      branding={{
        logo: <img src={logo} alt="Nitro logo" />,
        title: "",
      }}
    >
      <DashboardLayout hideNavigation>
        <Typography
          variant="h3"
          alignItems="center"
          style={{ textAlign: "center", marginTop: "50px" }}
          gutterBottom
        >
          Verify Email
        </Typography>

        <span style={{ textAlign: "center" }}>
          A verification email has been sent to your email.
        </span>
        <span style={{ textAlign: "center" }}>
          If you have not received your email please check your spam or request
          an another from <Link>here</Link>
        </span>
        <span style={{ textAlign: "center" }}>
          Go to <Link to={"/login"}>Login</Link>
        </span>
      </DashboardLayout>
    </AppProvider>
  );
}
