import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import logo from "./../../images/logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function TopNavigation() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.clear("auth-token");
    window.location.replace("/login");
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        height: "80px",
        borderBottom: "1px solid lightgray",
        display: "flex",
        verticalAlign: "center",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        <img
          src={logo}
          style={{ width: "90px", marginLeft: "20px" }}
          alt="Logo"
        />
        ;
      </div>
      <div>
        <div>
          <IconButton
            color="primary"
            aria-label="Logout"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ marginRight: "20px" }}
          >
            <AccountCircleIcon color="primary" />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => window.location.replace("/admin")}>
              Dashboard
            </MenuItem>
            <MenuItem onClick={() => window.location.replace("/profile")}>
              Profile
            </MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
