import React, { useEffect, useState } from "react";
import TopNavigation from "./TopNavigation";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import axiosInstance from "../../axiosConfig";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
export default function Profile() {
  const [user, setUser] = useState([]);
  const fetchProfile = async () => {
    try {
      const result = await axiosInstance({
        method: "get",
        url: "user",
        responseType: "json",
      });

      if (result.data.success === true) {
        console.log(result.data.data);

        setUser(result.data.data);
      }
    } catch (error) {
      console.log("server error Please try again");
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <TopNavigation />
        <Typography
          variant="h3"
          alignItems="center"
          style={{ textAlign: "center", marginTop: "50px" }}
          gutterBottom
        >
          Profile
        </Typography>
        <Grid
          container
          rowSpacing={1}
          style={{ padding: 50, justifyContent: "center" }}
        >
          <Grid size={{ xs: 12, md: 7, lg: 5, xl: 6 }}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "10px",
                justifyContent: "left",
                alignItems: "center",
                padding: "20px",
              }}
              component={Paper}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="User Name" secondary={user.name} />
                </ListItem>
                <ListItem sx={{ marginTop: "20px" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <EmailIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="User Email" secondary={user.email} />
                </ListItem>
                <ListItem sx={{ marginTop: "20px" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <VerifiedUserIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Email Verified"
                    secondary={
                      user.email_verified_at === null
                        ? "Not Verified"
                        : "Verified"
                    }
                  />
                </ListItem>
                <ListItem sx={{ marginTop: "20px" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <VerifiedUserIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="User Team"
                    secondary={user.team_name === null ? "N/A" : user.team_name}
                  />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
