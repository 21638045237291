import { useState, Fragment, React } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../../axiosConfig";
import Chip from "@mui/material/Chip";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  FormContainer,
  TextFieldElement,
  RadioButtonGroup,
} from "react-hook-form-mui";
import { Alert, Badge } from "@mui/material";

export default function BulkStatusChange(props) {
  const { ids } = props;

  const [OpenChangeStatus, setOpenChangeStatus] = useState(false);
  const [showalert, setshowalert] = useState(false);
  const [alerttext, setAlertText] = useState(null);

  const updateStatus = async (data) => {
    const d = { ...data, ids: ids };

    try {
      const result = await axiosInstance({
        method: "post",
        url: "status/bulk",
        responseType: "json",
        data: d,
      });

      if (result.data.success === true) {
        setOpenChangeStatus(false);
        window.location.reload();
      } else {
        setAlertText(result.data.message);
        setshowalert(true);
      }
    } catch (error) {
      console.log(error);
      setAlertText(error.message);
      setshowalert(true);
    }
  };

  const handleClickOpen = () => {
    setOpenChangeStatus(true);
  };

  const handleClose = () => {
    setOpenChangeStatus(false);
  };
  return (
    <Fragment>
      <Badge color="primary" onClick={handleClickOpen}>
        <EditNoteIcon fontSize="medium" color="action" />
      </Badge>

      <Dialog maxWidth={"lg"} open={OpenChangeStatus} onClose={handleClose}>
        <FormContainer onSuccess={(data) => updateStatus(data)}>
          <DialogTitle>Update Status</DialogTitle>
          {showalert && <Alert severity="error">{alerttext}</Alert>}

          <DialogContent>
            <DialogContentText>
              <RadioButtonGroup
                label="Status"
                name="status"
                options={[
                  {
                    id: "Investigation Started",
                    label: "Investigation Started",
                  },
                  {
                    id: "Repair Arranged",
                    label: "Repair Arranged",
                  },
                  {
                    id: "Waiting On Parts",
                    label: "Waiting On Parts",
                  },
                  {
                    id: "Parts Arrived",
                    label: "Parts Arrived",
                  },
                  {
                    id: "Repair Completed",
                    label: "Repair Completed",
                  },
                ]}
                required
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>close</Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </Fragment>
  );
}
