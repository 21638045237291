import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import DataTable from "./DataTable";
import Typography from "@mui/material/Typography";
import Mainform from "./MainForm";
import axiosInstance from "../../axiosConfig";

export default function Submitform() {
  const [TicketsData, setTicketsData] = useState(null);

  async function fetchData(params) {
    try {
      const result = await axiosInstance({
        method: "get",
        url: "ticket",
        responseType: "json",
      });
      setTicketsData(result.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant="h3"
        alignItems="center"
        style={{ textAlign: "center", marginTop: "50px" }}
        gutterBottom
      >
        Create An Issue Ticket
      </Typography>

      <Grid
        container
        rowSpacing={1}
        style={{ padding: 50 }}
        columnSpacing={{ xs: 1, xl: 2, lg: 2 }}
      >
        <Grid size={{ xs: 12, md: 12, lg: 5, xl: 5 }}>
          <Mainform />
        </Grid>
        <Grid size={{ xs: 12, md: 12, lg: 7, xl: 7 }}>
          <DataTable TicketsData={TicketsData} />
        </Grid>
      </Grid>
    </Box>
  );
}
