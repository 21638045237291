import { useState, Fragment, React } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";

export default function Viewimage(props) {
  const { status, image } = props;

  const [OpenChangeStatus, setOpenChangeStatus] = useState(false);

  const handleClickOpen = () => {
    setOpenChangeStatus(true);
  };

  const handleClose = () => {
    setOpenChangeStatus(false);
  };
  return (
    <Fragment>
      <IconButton onClick={handleClickOpen} aria-label="note">
        <Chip color={"info"} label={status} />
      </IconButton>

      <Dialog maxWidth={"lg"} open={OpenChangeStatus} onClose={handleClose}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {image ? <img src={image} /> : "No Image"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
