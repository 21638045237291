import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig";
import { useParams } from "react-router-dom";

export default function EmailVerify() {
  const [success, setSuccess] = useState(false);
  const [text, setText] = useState("Loading");
  let { id, token } = useParams();
  useEffect(
    () => async () => {
      const result = await axiosInstance({
        method: "get",
        url: `user/verify/${id}/${token}`,
        responseType: "json",
      });

      if (result.data.success) {
        setSuccess(true);
        const timer = setTimeout(() => {
          window.location.replace("/login");
        }, 2000);
      } else {
        setText("your Link has been expired please generate a new one ");
      }

      return () => {
        clearTimeout();
      };
    },
    []
  );

  return success ? <div>Your email has been verified </div> : <div>{text}</div>;
}
