import React, { Component, useContext, useEffect, useState } from "react";
import Dashboard from "./components/User/Dashboard";
import Main from "./components/Admin/Main";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PublicForm from "./components/User/PublicForm";
import Login from "./components/Admin/Login";
import Signup from "./components/Admin/Signup";
import Notfound from "./components/Notfound";
import Profile from "./components/Admin/Profile";
import axiosInstance from "./axiosConfig";
import {
  AuthContext,
  AuthContextProvider,
} from "./ContextProviders/AuthProvider";
import { ProtectedRoute } from "./components/Admin/ProtectedRoute";
import Verification from "./components/Admin/Verification";
import EmailVerify from "./components/Admin/EmailVerify";

function App() {
  const token = localStorage.getItem("auth-token");

  React.useEffect(
    () => async () => {
      if (token)
        try {
          await axiosInstance({
            method: "get",
            url: "jwt",
            responseType: "json",
          });
          console.log("auth success");
        } catch (error) {
          console.log("auth failed 1");
        }
    },
    []
  );
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Notfound />} />
          <Route exact path="/" element={<PublicForm />} />
          <Route exact path="/nitropos" element={<Dashboard />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/verification/:id" element={<Verification />} />
          <Route exact path="/verify/:id/:token" element={<EmailVerify />} />
          <Route exact path="/profile" element={<ProtectedRoute />}>
            <Route exact path="/profile" element={<Profile />} />
          </Route>
          <Route exact path="/admin" element={<ProtectedRoute />}>
            <Route exact path="/admin" element={<Main />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
