import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import TicketList from "./TicketList";
import { Button, Menu, MenuItem } from "@mui/material";

export default function Dashboard() {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant="h3"
        alignItems="center"
        style={{ textAlign: "center", marginTop: "50px" }}
        gutterBottom
      >
        Admin Dashboard
      </Typography>
      <Grid
        container
        rowSpacing={1}
        style={{ padding: 50, justifyContent: "center" }}
      >
        <Grid size={{ xs: 12, md: 12, lg: 8, xl: 8 }}>
          <TicketList />
        </Grid>
      </Grid>
    </Box>
  );
}
