import axios from "axios";
import { Navigate, replace, useNavigate } from "react-router-dom";
const token = localStorage.getItem("auth-token");
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: { Authorization: `${token}` },
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.status === 401) {
      if (
        window.location.pathname === "/admin" ||
        window.location.pathname === "/profile"
      ) {
        localStorage.clear("auth-token");
        window.location.replace("/login");
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
