import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddNotes from "./AddNotes";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import ShowNotes from "./ShowNotes";
import ChangeStatus from "./ChangeStatus";
import { tableCellClasses } from "@mui/material/TableCell";

import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function DataTable(props) {
  const { TicketsData } = props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "110px" }} align="left">
                Opened on
              </StyledTableCell>
              <StyledTableCell align="center">Issue</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Issue Type</StyledTableCell>
              <StyledTableCell align="center">Note</StyledTableCell>
              <StyledTableCell align="center">actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {TicketsData?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.issue_date}
                </TableCell>
                <TableCell
                  style={{ textTransform: "capitalize" }}
                  align="center"
                >
                  {row.machine_issue ? row.machine_issue : row.sub_issue_type}
                </TableCell>
                <TableCell align="center">
                  {row.issue_type === "IT" ? (
                    <Chip
                      label={row.mainstatus ? row.mainstatus : row.status}
                      color="info"
                    />
                  ) : (
                    <ChangeStatus
                      status={row.mainstatus ? row.mainstatus : row.status}
                      id={row.id}
                    />
                  )}
                </TableCell>
                <TableCell align="center">{row.issue_description}</TableCell>
                <TableCell align="center">
                  <Chip
                    label={
                      row.issue_type === "IT"
                        ? "IT Services Request"
                        : "Repair Request"
                    }
                    color={row.issue_type === "IT" ? "warning" : "error"}
                  />
                </TableCell>
                <TableCell align="center">
                  <ShowNotes Ticket_id={row.id} />
                </TableCell>
                <TableCell align="center">
                  <AddNotes id={row.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
